import { useContext } from "react";
import { SupabaseContext } from "#/app/_providers/Supabase";

/**
 * Provides access to the Supabase client with the user's session.
 * Use only in the browser.
 */
export const useSupabase = () => {
  let context = useContext(SupabaseContext);
  if (context === undefined) {
    throw new Error("useSupabase must be used inside SupabaseProvider");
  } else {
    return context;
  }
}