import React from "react"
import { SegmentContext } from "#/app/_providers/Segment"

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = React.useContext(SegmentContext)
  if (!result) {
    throw new Error('Context used outside of its Provider!')
  }
  return result
}