export default function Beta() {
  return (
    <div>
      <div className="flex flex-col space-y-4 px-6 py-6 rounded-lg items-center justify-center">
        <h2 className="text-[120px] font-bold">🎬</h2>
        <h2 className="text-3xl font-bold">{`We're currently in private beta.`}</h2>
        <p className="text-lg">To get an invitation, <a href="https://www.clip.fm/get-started" target="_blank" rel="noreferrer" className="font-bold hover:underline text-blue-500">join the waitlist here.</a></p>
      </div>
    </div>
  )
}