'use client';

import React, { FC } from 'react'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { NEXT_PUBLIC_SEGMENT_WRITE_KEY } from '#/lib/clients/segment-http'

export const SegmentContext = React.createContext<undefined | AnalyticsBrowser>(undefined)

export const SegmentProvider: FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const analytics = React.useMemo(() => {
    console.log(`AnalyticsBrowser loading...`, NEXT_PUBLIC_SEGMENT_WRITE_KEY);

    // Note: this automatically injects into window.analytics, but we could
    // also refactor with useSegment()
    return AnalyticsBrowser.load({ 
      writeKey: NEXT_PUBLIC_SEGMENT_WRITE_KEY || '',
    });
  }, [])

  return (
    <SegmentContext.Provider value={analytics}>
      {children}
    </SegmentContext.Provider>
  )
}
