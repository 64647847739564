import Env from "#/lib/env-utils";

//// Environment

// FIXME
export const ENVIRONMENT_NAME = (
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' 
    ? 'staging'
    : process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'production'
    : 'development'
);

//// Supabase

export const NEXT_PUBLIC_SUPABASE_URL = Env.require(
  process.env.NEXT_PUBLIC_SUPABASE_URL,
  "NEXT_PUBLIC_SUPABASE_URL"
);
export const NEXT_PUBLIC_SUPABASE_ANON_KEY = Env.require(
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
  "NEXT_PUBLIC_SUPABASE_ANON_KEY"
);

//// Web

export const NEXT_PUBLIC_ORIGIN = Env.require(
  process.env.NEXT_PUBLIC_ORIGIN,
  "NEXT_PUBLIC_ORIGIN"
);

/**
 * On localhost, we use different origins for the web and transcription services.
 * (transcription uses the ngrok url)
 * */
export const NEXT_PUBLIC_ORIGIN_WEBHOOK = Env.require(
  process.env.NEXT_PUBLIC_ORIGIN_WEBHOOK ? process.env.NEXT_PUBLIC_ORIGIN_WEBHOOK : NEXT_PUBLIC_ORIGIN,
  "NEXT_PUBLIC_ORIGIN"
);
