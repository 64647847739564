'use client'

import clsx from "clsx";
import {DetailedHTMLProps, FC, InputHTMLAttributes} from "react";

type InputProps = {
  error?: string | null;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Input: FC<InputProps> = ({error, className, ...props}) => {
  return (
    <>
      <input
        className={clsx(
          "bg-black focus:bg-black focus:border-white appearance-none border-[1px] border-border rounded-xl w-full py-4 px-4 text-white leading-tight focus:outline-none ", className
        )}
        {...props}
      />
      {error && <p className="ml-2 mt-1 text-left text-sm text-red-500">{error}</p>}
    </>
  )
}

export default Input;
