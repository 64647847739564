'use client'

import clsx from "clsx";
import {ButtonHTMLAttributes, DetailedHTMLProps, FC} from "react";
import style from "#/app/_components/Common.module.scss";

const SubmitButton: FC<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & { noColor?: boolean }> = ({ className, children, noColor, ...props }) => {
  return (
    <button
      className={clsx(
        `flex items-center justify-center`,
        'w-full mt-3 inline-flex justify-center rounded-md px-3 py-3 font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 hover:ring-blue-100',
        !noColor && ((props.disabled) ? style.submitButtonDisabled : style.submitButton),
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export default SubmitButton;
