'use client'

/**
 * Followed this guide for authentication:
 * https://supabase.com/docs/guides/auth/auth-helpers/nextjs-server-components
 */
import {FC, FormEvent, useState} from "react";
import {useSupabase} from '#/app/_hooks/useSupabase';
import { useSearchParams } from "next/navigation";
import Input from "#/app/_components/Forms/Input";
import SubmitButton from "#/app/_components/Forms/SubmitButton";
import Centered from "#/app/_components/Centered";
import toast from "react-hot-toast";
import {Spinner} from "#/app/_components/Spinner";
import Beta from "#/app/(auth)/Beta";
import {useRouter} from "next/navigation";
import {z, ZodSchema} from "zod";
import clsx from "clsx";
import { useAnalytics } from "#/app/_hooks/useSegment";

async function isValid<T>(schema: ZodSchema<T>, input: any): Promise<boolean> {
  const result = schema.safeParse(input);
  return result.success;
}

const LoginForm: FC = () => {
  const {supabase} = useSupabase();
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<null | 'done' | 'submitting' | 'error'>(null);
  const [useMagicLink, setUseMagicLink] = useState(false);
  const analytics = useAnalytics();

  // Get YouTube URL from landing page form
  const searchParams = useSearchParams();
  const video_url = searchParams?.get('video_url');

  const handleGoogleAuth = async (event: any) => {
    event.preventDefault();

    try {
      analytics.track('Sign In Initiated', {
        provider: 'google',
        query: {
          video_url,
        }
      });

      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${process.env.NEXT_PUBLIC_ORIGIN}/login/finish?video_url=${video_url}`
        }
      })

      if (error) throw error;
    } catch (e) {
      console.log('error', e);
    }
  }

  const handleLoginOtp = async (event: any) => {
    event.preventDefault();

    const valid = await isValid(z.string().email().nonempty(), email);
    if (!valid) {
      toast.error("Please enter a valid email address");
      return;
    }

    setStatus('submitting');
    
    try {
      analytics.track('Sign In Form Submitted', {
        provider: 'otp',
        email: email.toLowerCase(),
        query: {
          video_url
        }
      });

      const {error} = await supabase.auth.signInWithOtp({
        email: email.toLowerCase(),
        options: {
          // FIXME: this isn't working locally
          emailRedirectTo: `${process.env.NEXT_PUBLIC_ORIGIN}/login/finish?video_url=${video_url}`,
          shouldCreateUser: true,
        },
      });

      if (error) throw error;
      setStatus('done');
    } catch (e) {
      setStatus('error');
    }
  }

  const handleEnterEmail = (event: FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  }

  return (
    <Centered>
      <div className="flex flex-col text-center">
        {status === 'done' ? (
          <div>
            <div className="flex flex-col space-y-1 px-6 py-6 rounded-lg items-center justify-center bg-green-500/30">
              <p className="text-lg">Check your email <strong>{email}</strong> for a link to sign in.</p>
            </div>
          </div>
        ) : (status === 'error') ? (
          <Beta/>
        ) : (
          <>
            {/* i like button */}
            <button className="cursor-default" onClick={() => setUseMagicLink(false)}>
              <h2 className="text-[50px] font-bold">🎬</h2>
              <h1 className="text-3xl font-bold mb-1">ClipFM</h1>
              <p className="mb-4 text-secondary">Log in or sign up to continue to ClipFM.</p>
            </button>
            {useMagicLink ? (
              <form onSubmit={handleLoginOtp} className="flex flex-col space-y-3 min-w-[325px]">
                <Input onChange={handleEnterEmail} placeholder="Email" name="email" type="email" autoFocus/>
                <SubmitButton type="submit" className="h-16" noColor={status === 'submitting'} disabled={status === 'submitting'}>
                  {status === 'submitting' ? <Spinner size={5}/> : "Send me a signin link →"}
                </SubmitButton>
              </form>
            ) : (
              <button
                onClick={handleGoogleAuth}
                className={clsx(
                  `flex items-center justify-center`,
                  "bg-transparent hover:bg-gray-900",
                  `shadow border-2 border-border  
                focus:shadow-outline focus:outline-none 
                text-white font-bold py-4 px-4 rounded-xl w-full`
                )}
              >
                <img src="images/google.png" className="h-6 w-6" alt="YouTube Logo"/>
                <p className="ml-4">Continue with Google →</p>
              </button>
            )}
            <p className="text-secondary mt-8">
              Continue with&nbsp;
              {useMagicLink ? (
                <button onClick={() => setUseMagicLink(false)}
                        className="font-bold hover:underline text-blue-500">Google Auth</button>
              ) : (
                <button onClick={() => setUseMagicLink(true)}
                        className="font-bold hover:underline text-blue-500">magic link</button>
              )} instead.
            </p>
          </>
        )}
      </div>
    </Centered>
  )
}

export default LoginForm;


// const handleLoginOAuth = async () => {
//   const {data, error} = await supabase.auth.signInWithOAuth({
//     provider: 'google',
//   });
//   router.refresh();
//   if (error) {
//     console.log({error})
//   }
// }

/* <p>or</p>
<div>
  <SubmitButton onClick={handleLoginOAuth} noColor>
    <Image src={googleIcon} alt="google logo" width={23}/>&nbsp;&nbsp;Sign in with Google
  </SubmitButton>
  <div className="text-sm mt-2 text-gray-500">
    Need an account? <Link className="text-blue-500" href="/signup">Sign up</Link> instead.
  </div>
</div> */